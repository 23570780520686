module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":" Experience Store Doesburg","short_name":"ExperienceStoreDoesburg","start_url":"/?pwa=true","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffa9c6ca536605501a3a9f0cb301bfcb"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5996ba89e3164ce0b6a06c68dbe00644@o1430399.ingest.sentry.io/4504876343033856","tracesSampleRate":1,"release":"gatsby@2025-01-26T12:48:07.313Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"headers":{"/voorraad/*":["Content-Security-Policy: frame-ancestors 'self' https://crm.walcu.com;"]},"sitemapExcludes":["/bedankt/**/*","/voorraad/model/**/*","/voorraad/aanvraag/model/**/*"],"sourceStrapi":{"themes":{"autochat":true,"vehicles":true,"rating":true,"blog":true,"pagebuilder":true,"messagebubble":true,"faq":true,"documents":true,"spincar":true,"vehicleLease":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-messagebubble/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/doesburg/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-autochat/gatsby-browser.js'),
      options: {"plugins":[],"assistantId":"2fd01c1d-5549-457f-8b08-b89d87748a72","accessToken":"2e7ed496ff50bf1be459c0ac2960cf653c92333c4eb933fd861ddb2c83c6e4a4cec056d925e9675783d5b94b3e3920eea8bba02b4d3afa7009f80a77"},
    },{
      plugin: require('../../../packages/gatsby-theme-times-modal-opened/gatsby-browser.js'),
      options: {"plugins":[],"maxTimesModalOpenedAllowed":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"id":"GTM-KQ7TX3DK","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-55DF9W3Q7W"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
